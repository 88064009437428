@font-face {
  font-display: fallback;
  font-family: "graphik-extralight";
  src: local("graphik"), url("../assets/fonts/GraphikExtralight.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: fallback;
  font-family: "graphik-light";
  src: local("graphik"), url("../assets/fonts/GraphikLight.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: fallback;
  font-family: "graphik-medium";
  src: local("graphik"), url("../assets/fonts/GraphikMedium.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: fallback;
  font-family: "graphik-regular";
  src: local("graphik"), url("../assets/fonts/GraphikRegular.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: fallback;
  font-family: "graphik-semibold";
  src: local("graphik"), url("../assets/fonts/GraphikSemibold.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: fallback;
  font-family: "sharpgrotesk-book";
  src: local("sharpgrotesk"), url("../assets/fonts/SharpGrotesk-Book25.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: fallback;
  font-family: "sharpgrotesk-light";
  src: local("sharpgrotesk"), url("../assets/fonts/SharpGrotesk-Light25.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: fallback;
  font-family: "sharpgrotesk-medium";
  src: local("sharpgrotesk"), url("../assets/fonts/SharpGrotesk-Medium25.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: fallback;
  font-family: "sharpgrotesk-semibold";
  src: local("sharpgrotesk"), url("../assets/fonts/SharpGrotesk-SemiBold25.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}
